<template>
  <div>
      <div class="bottom-thumbnail">
          <div class="bottom-img-cover">
              <div @click="locateScence(1)">
                  <img src="homePage.png"/>
              </div>
              <div @click="locateScence(2)">
                  <img src="2.png"/>
              </div>
              <div @click="locateScence(3)">
                  <img src="3.png"/>
              </div>
              <div @click="locateScence(4)">
                  <img src="4.png"/>
              </div>
              <div @click="locateScence(5)">
                  <img src="5.png"/>
              </div>
              <div @click="locateScence(6)">
                  <img src="6.png"/>
              </div>
              <div @click="locateScence(7)">
                  <img src="7.png"/>
              </div>
          </div>
      
      </div>
  </div>
</template>

<script>



export default {
  name: '',
  data(){
    return{

    }
  },
  methods:{
    locateScence(type){
     window.game.changeScence(type);
    }
  }
}
</script>
<!-- 两种样式 768px为边界线-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 768px) {
    .bottom-thumbnail{
      height: 70px;
      display:inline-block;
    }
    .bottom-thumbnail .bottom-img-cover {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-x: scroll;
    }
    .bottom-thumbnail .bottom-img-cover > div{
      width: 57px;
      height: 57px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid rgb(255,255,255);
      margin-left: 3px;
      cursor: pointer;
    }
    .bottom-thumbnail .bottom-img-cover > div > img{
      width: 57px;
      height: 57px;
    }
}
@media screen and (min-width: 768px) {
    .bottom-thumbnail{
      height: 100px;
      display:inline-block;
      width: 100%;
    }
    .bottom-thumbnail .bottom-img-cover {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-x: scroll;
    }
    .bottom-thumbnail .bottom-img-cover > div{
      width: 80px;
      height: 80px;
      border: 3px solid rgb(255,255,255);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      cursor: pointer;
    }
    .bottom-thumbnail .bottom-img-cover > div > img{
      width: 80px;
      height: 80px;
    }
}
</style>
